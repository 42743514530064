import React from 'react';
import { InputBase, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StyledDiv from 'views/common/components/UI/SearchBar/SearchBar.styles';
import { useTranslation } from 'next-i18next';

const SearchBar = (props) => {
  const { t } = useTranslation();
  const {
    value,
    onChange,
    onKeyDown,
    className,
    inputRef,
    placeholder = t('fe_er_common_search_bar:search'),
  } = props;
  return (
    <StyledDiv className={className}>
      <Box>
        <div className="SBSearchIconWrapper">
          <SearchIcon />
        </div>
        <InputBase
          value={value}
          inputRef={inputRef}
          className="SBInputBase"
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          type="search"
        />
      </Box>
    </StyledDiv>
  );
};

export { SearchBar };
