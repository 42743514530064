import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledMarkerBox = styled(Box)(({ theme }) => ({
  '&.markerCard': {
    position: 'relative',
    width: theme.spacing(6),
    maxHeight: theme.spacing(5),
    lineHeight: theme.spacing(5),
    background: theme.palette.primary.main,
    border: `${theme.spacing(0.125)} solid ${theme.palette.primary.dark}`,
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(3.75),
    textAlign: 'center',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.spacing(1),
    transition: ' all ease .5s',
    transform: 'translate(-50%, -50%)',
    '&:after': {
      position: 'absolute',
      bottom: theme.spacing(-1.25),
      left: '50%',
      marginLeft: theme.spacing(-1.25),
      content: '""',
      display: 'block',
      borderLeft: `${theme.spacing(1.25)}  solid transparent`,
      borderRight: `${theme.spacing(1.25)}  solid transparent`,
      borderTop: `${theme.spacing(1.25)}  solid ${theme.palette.primary.main}`,
    },
    '& img': {
      display: 'none',
    },
    '&.active': {
      width: theme.spacing(8),
      zIndex: 1,
      '& img': {
        display: 'inline-block',
        marginRight: theme.spacing(0.8),
      },
    },
  },
}));

export default StyledMarkerBox;
