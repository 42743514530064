/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Box } from '@mui/material';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import StyledBox from 'views/common/components/UI/MapWithGroups/LocationListGroups/LocationListGroups.styles';
// import { useTranslation } from 'next-i18next';
import { AcccordionLocations } from 'views/common/components/UI/MapWithGroups/AcccordionLocations';

const LocationListGroups = (props) => {
  const {
    locationsGroup,
    handleEmailButtonClick,
    prepareMapUrl,
    handleBookNowButtonCLick,
    handleLocationCardButtonClick,
    handleLocationCardHover,
    target,
    selectedLocationId,
    searchValue,
    darkMode,
  } = props;
  // const { t } = useTranslation('fe_er_common_map_with_groups');
  return (
    <>
      <StyledBox id="locationsList" className={`${darkMode && 'LLGDark'}`}>
        <Box>
          <Box className="LLGAccordionContiner">
            {locationsGroup.map((location, index) => (
              <AcccordionLocations
                location={location}
                key={index}
                handleEmailButtonClick={handleEmailButtonClick}
                prepareMapUrl={prepareMapUrl}
                handleBookNowButtonCLick={handleBookNowButtonCLick}
                handleLocationCardButtonClick={handleLocationCardButtonClick}
                handleLocationCardHover={handleLocationCardHover}
                target={target}
                selectedLocationId={selectedLocationId}
                searchValue={searchValue}
                darkMode={darkMode}
              />
            ))}
          </Box>
          {/* <Box className="LLGButtonContiner">
            <Button
              variant="text"
              color="secondary"
              endIcon={<ArrowDropDownIcon />}>
              {t('fe_er_common_map_with_groups:show_more')}
            </Button>
          </Box> */}
        </Box>
      </StyledBox>
    </>
  );
};

export { LocationListGroups };
