import React, { useRef, useState } from 'react';
import {
  Grid, Typography, Box, Button,
} from '@mui/material';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';
import { useTranslation } from 'next-i18next';
import { LocationListGroups } from 'views/common/components/UI/MapWithGroups/LocationListGroups';
import dynamic from 'next/dynamic';
import {
  StyledGrid,
  StyledSearchBar,
} from 'views/common/components/UI/MapWithGroups/MapWithGroups.styles';
import { useSelector } from 'react-redux';

const Map = dynamic(() => import('views/common/components/UI/Map').then((file) => file.Map));

const MapWithGroups = (props) => {
  const { t } = useTranslation('fe_er_common_map_with_groups');
  const {
    className,
    mapGroupsTitle,
    locationCount,
    locationsGroup,
    defaultZoom,
    mapCenter,
    mapLocations,
    mapIcon,
    mapIconAlt,
    handleTextFieldSearch,
    handleEmailButtonClick,
    handleMapLogoClick,
    prepareMapUrl,
    handleMapOnChange,
    handleBookNowButtonCLick,
    renderMapMarkers,
    handleLocationCardButtonClick,
    handleLocationCardHover,
    target,
    selectedLocationId,
    placeholder,
    darkMode,
  } = props;

  const { isCrawler } = useSelector((state) => state.globalData);
  const [showMap, setShowMap] = useState(!isCrawler);
  const searchInputRef = useRef();
  const handleShowMapClick = () => {
    setShowMap(true);
  };
  return (
    <StyledGrid
      container
      columnSpacing={{ xs: 5 }}
      className={`${className} ${darkMode && 'MWGDark'}`}>
      <Grid item lg={6} md={12} className="MWGLocationListSide">
        <Box className="MWGTitleSection">
          <Typography500 variant="h3" component="p" className="MWGMainTitle">
            {mapGroupsTitle}
          </Typography500>
          <Typography
            variant="subtitle2"
            component="span"
            className="MWGLocationsNumber">
            <Typography component="span" className="MWGWeight300" mr={1}>
              {locationCount}
            </Typography>
            <Typography component="span" className="MWGWeight300">
              {t('fe_er_common_map_with_groups:locations')}
            </Typography>
          </Typography>
        </Box>
        {handleTextFieldSearch && (
          <StyledSearchBar
            inputRef={searchInputRef}
            onChange={handleTextFieldSearch}
            placeholder={placeholder}
            className={`${darkMode && 'MWGDark'}`}
          />
        )}
        <LocationListGroups
          locationsGroup={locationsGroup}
          handleEmailButtonClick={handleEmailButtonClick}
          prepareMapUrl={prepareMapUrl}
          handleBookNowButtonCLick={handleBookNowButtonCLick}
          handleLocationCardButtonClick={handleLocationCardButtonClick}
          handleLocationCardHover={handleLocationCardHover}
          selectedLocationId={selectedLocationId}
          target={target}
          searchValue={searchInputRef?.current?.value}
          darkMode={darkMode}
        />
      </Grid>
      <Grid item lg={6} md={12}>
        <Box className="MWGMapSide">
          {isCrawler && !showMap ? (
            <Box className="MWGMapPlaceholder">
              <Button onClick={handleShowMapClick}>Show map</Button>
            </Box>
          ) : (
            <Map
              mapCenter={mapCenter}
              mapLocations={mapLocations}
              mapIcon={mapIcon}
              mapIconAlt={mapIconAlt}
              handleMarkerClick={handleMapLogoClick}
              mapZoom={defaultZoom}
              handleMapOnChange={handleMapOnChange}
              renderMapMarkers={renderMapMarkers}
              darkMode={darkMode}
            />
          )}
        </Box>
      </Grid>
    </StyledGrid>
  );
};

export { MapWithGroups };
