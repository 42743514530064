/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Typography500 from 'views/common/components/UI/Typography500';
import { LocationCard } from 'views/common/components/UI/LocationCard';
import PATHS from 'lib/navigation/paths';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import { useTranslation } from 'next-i18next';

const AcccordionLocations = (props) => {
  const {
    handleEmailButtonClick,
    prepareMapUrl,
    handleBookNowButtonCLick,
    handleLocationCardButtonClick,
    handleLocationCardHover,
    target,
    selectedLocationId,
    location,
    searchValue,
    darkMode,
  } = props;
  const { t } = useTranslation('fe_er_common_map_with_groups');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [expanded, setExpanded] = useState(isDesktop);
  const toggleAccordion = () => {
    setExpanded((prev) => !prev);
  };
  useEffect(() => {
    if (searchValue) {
      setExpanded(true);
    } else setExpanded(false);
  }, [searchValue]);
  return (
    <Accordion expanded={isDesktop || expanded}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        id={location.name}
        onClick={toggleAccordion}>
        {location.name ? (
          <Typography500
            variant="h5"
            component={NavigatorLink}
            href={PATHS.motorcycleRentalsSlug(location.slug)}
            className="LIGTitle">
            {location.name}
          </Typography500>
        ) : (
          <Typography500 variant="h5" className="LIGTitle LIGOtherLoc">
            {t('fe_er_common_map_with_groups:other_loc')}
          </Typography500>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {location.locations.map((item, idx) => (
          <div key={item.id}>
            <LocationCard
              id={item.id}
              address={item.full_address}
              link={prepareMapUrl(item.map_url)}
              title={item.name}
              phone={item.phone}
              email={item.email}
              location={PATHS.urlSlugs(item.slug)}
              handleEmailButtonClick={handleEmailButtonClick}
              handleBookNowButtonCLick={handleBookNowButtonCLick}
              locationIndex={item.order || idx + 1}
              activeCard={item.activeCard || selectedLocationId === item.id}
              handleLocationCardButtonClick={handleLocationCardButtonClick}
              handleLocationCardHover={handleLocationCardHover}
              target={target}
              darkMode={darkMode}
            />
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export { AcccordionLocations };
